export function round(number, decimalPlaces = 2) {
	return parseFloat(number).toFixed(decimalPlaces);
}

export function numberFormat(number, minimumFractionDigits = 2) {
	return new Intl.NumberFormat("en-US", { minimumFractionDigits }).format(round(number, minimumFractionDigits));
}

export function numberToString(n) {
	const special = [
		"zeroth",
		"first",
		"second",
		"third",
		"fourth",
		"fifth",
		"sixth",
		"seventh",
		"eighth",
		"ninth",
		"tenth",
		"eleventh",
		"twelfth",
		"thirteenth",
		"fourteenth",
		"fifteenth",
		"sixteenth",
		"seventeenth",
		"eighteenth",
		"nineteenth",
	];
	const deca = ["twent", "thirt", "fort", "fift", "sixt", "sevent", "eight", "ninet"];
	
	let result  = "";

	if (n < 20) result = special[n];
	else if (n % 10 === 0) result = deca[Math.floor(n / 10) - 2] + "ieth";
	else result = deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
	
	return result.replace(/\b[a-z]/g, function(letter) {		
		return letter.toUpperCase();
	});
}
